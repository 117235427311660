const linksContainer = document.querySelector('.social-links__wrapper');

const readJsonFile = (file, callback) => {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = () => {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
            callback(rawFile.responseText);
        }
    }
    rawFile.send(null);
}

const pushLinksToContainer = (container, data) => {
    if (container) {
        data.forEach(block => {
            const title = document.createElement('h3');
            title.classList.add('social-links__title')
            title.innerText = block.name || '';

            if (block.links && block.links.length > 0) {
                const listContainer = document.createElement('ul');
                listContainer.classList.add('social-links__list');

                block.links.forEach(linkParams => {
                    const listItem = document.createElement('li');
                    listItem.classList.add('social-links__list-item');

                    const itemLink = document.createElement('a');
                    itemLink.classList.add('social-links__link');

                    itemLink.style.backgroundColor = linkParams.bgColor || '';
                    itemLink.style.color = linkParams.txtColor || '';

                    itemLink.href = linkParams.link;
                    itemLink.innerText = linkParams.title;

                    listItem.appendChild(itemLink);
                    listContainer.appendChild(listItem);
                });

                container.appendChild(title);
                container.appendChild(listContainer);
            }

        });
    }
}

readJsonFile("json/links.json", (string) => {
    var data = JSON.parse(string);
    if (data && data.length > 0) {
        pushLinksToContainer(linksContainer, data);
    }
});